import React, { useEffect } from "react";
import { useAppState } from "@state";
import { PageSizeObserver } from "@base";
import loadable from "@loadable/component";
import { LazyMotion } from "framer-motion";

const LakotaGcHeader = loadable(() => import("@organisms/LakotaGcHeader"));
const GdprBanner = loadable(() => import("@organisms/GdprBanner"));
const Themer = loadable(() => import("../atoms/Themer"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};
const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, path, pageContext }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);
  const [{ layout }, dispatch] = useAppState();

  const { theme: _theme } = layout;
  const theme = pageContext?.theme || _theme;

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch({ type: "updateContributions" });
      // eslint-disable-next-line no-console
      console.log("Getting recent contributions");
    }, 20 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div
        id="app_layout"
        className={`transition duration-500 ${
          theme === "light" ? "bg-cream" : "bg-brown"
        }`}
      >
        <PageSizeObserver />
        <LakotaGcHeader />
        {/* <LakotaPageContainer path={path}> */}
        {children}
        {/* </LakotaPageContainer> */}
        <GdprBanner />
        <Themer />
        <div id="fb-root" />
      </div>
    </LazyMotion>
  );
};

export default DefaultLayout;
